// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:active,
    &:focus,
    &:hover {
      @content;
    }
  }

  @else {
    &:active,
    &:focus,
    &:hover {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}
// Make a div with a defiend height
@mixin aspect-ratio($ratio,$margin) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($ratio) * 100%;
  }

  &__content {
    position: absolute;
    top: $margin;
    left: $margin;
    right: $margin;
    bottom: $margin;
    margin: auto;
  }
}


@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}


@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  transition: $args;
}

@mixin transform($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

@mixin border-radius($args) {
  -webkit-border-radius: $args;
  -moz-border-radius: $args;
  -ms-border-radius: $args;
  border-radius: $args;
}

@mixin btn-effect-active(){
  box-shadow: inset 2px 5px 5px rgba(0,0,0,.15);
}

@mixin icon-styles(){
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin ellipsis(){
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    width: 100%;
}

@mixin if-ltr {
  @if $dir != rtl {
    @content;
  }
}

@mixin if-rtl {
  @if $dir == rtl {
    @content;
  }
}


@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
  &.focus,
  &:focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &.active,
  &:active,
  .open>&.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
    &.focus,
    &:focus,
    &:hover {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &.active,
  &:active,
  .open>&.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &.focus,
    &:focus,
    &:hover {
      background-color: $background;
      border-color: $border;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
}
